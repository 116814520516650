import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';


@inject(ApiService)
export class OrganizationService {
  
  constructor(apiService) {
    this.apiService = apiService;   
  }

  GetEmployeemasterDetails() {
    const defaultEmpId = '4';
     return this.apiService.getParam('/Employee/GetEmployeeMasterDetails?StatusCode=ACT&EmployeeID',defaultEmpId)
      .then(data => 
        { 
          return data;  
        }); 
  }


    EmployeeNamesForDropdown()
    {
        return this.apiService.get('/Employee/GetEmployeeNamesForDropdown')
            .then(data =>
            {
                return data;
            });
    }

    GetPoliciesData(empID){
      const statuscode="ACT";
        return this.apiService.getParameters(`/Organization/GetPolicies?StatusCode=${statuscode}&EmployeeID=${empID}`)
            .then(data =>
            {
                return data;
            });
    }
    GetAnnouncementsData(EventId){
        return this.apiService.getParam('/Organization/GetAnnouncements?EventId',EventId)
            .then(data =>
            {
                return data;
            });
    }

    GetEventTypeDropdown()
    {
        return this.apiService.get('/Attendance/GetEventTypes')
            .then(data =>
            {
                return data;
            });
    }

    GetManagerHierarchy(){
        return this.apiService.get('/Organization/GetOrgChartEmployees')
            .then(data =>
            {
                return data;
            });
    }

    GetReportingManagersearch(){
      const EmployeeID = '4';
      return this.apiService.getParam('/Employee/GetReportingManagersForDropdown?StatusCode=ACT&EmployeeID',EmployeeID)
            .then(data =>
            {
                return data;
            });
    }

    AddToRecentItems(employeeId,sitemapId){
      return this.apiService.getParameters(`/Menu/AddToRecentItems?EmployeeID=${employeeId}&SiteMapID=${sitemapId}`)
      .then(data =>{
          return data;
      });
  }

  orgchart(EmployeeId,ChartViewID,ViewByManager){
    return this.apiService.getParameters(`/Employee/GeneratePDF2?EmployeeID=${EmployeeId}&ChartViewID=${ChartViewID}&ViewByManager=${ViewByManager}`)  
    .then(data =>{
        return data;
    });
}
  
}
