import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';
import { JwtService } from './jwtservice';
import {User} from '../models/user';
import {SharedState} from '../state/sharedstate';
import { data } from 'jquery';
import {Router} from 'aurelia-router';
@inject(ApiService, JwtService, SharedState,Router)
export class TaskService{
  
  constructor(apiService, jwtService, sharedState,router) {
    this.apiService = apiService;
    this.jwtService = jwtService;
    this.sharedState = sharedState;
    this.router = router;
  }
  
  GettaskList(employeId){
    return this.apiService.getParam('/Dashboard/GetDashboardDetails?employeeID',employeId)
        .then(data=>{
            //console.log(data);
            return data
        });
}
  
  
}
