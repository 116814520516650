import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';
import { data } from 'jquery';


@inject(ApiService)
export class AppreciationService {
  
  constructor(apiService) {
    this.apiService = apiService;
   
  }
  // getting data to dropdown
  GetEmployeeNames() 
  {
     return this.apiService.get('/Employee/GetEmployeeNamesForDropdown')
      .then(data => 
        { 
          // console.log(data);
          return data;  
        }); 
  }
  // binding selected data to grid
  GetEmployeeNamesForGrid(empid) 
  {
     return this.apiService.getParam('/Employee/GetEmployeeGridBinding?EmployeeID', empid)
      .then(data => 
        { 
          console.log(data);
          return data;  
        }); 
  }
  // getting data to categories grid
  GetAppreciations()
  {
    
    return this.apiService.get('/Appreciation/GetAppreciationCategories')
    .then(data => {
      console.log(data);
      return data;
    })
  }
  
  SaveAppreciation(data){
    return this.apiService.post('/Appreciation/PostEmployeeGiveAppreciation/',data)
  }



  getMyAppreciationDetails(vemployeeID, Previousyear) {
    return this.apiService.getParameters(`/Appreciation/GetMyAppreciationDetails?employeeID=${vemployeeID}&year=${Previousyear}`)
    .then(data => {
      return data;
    })
    ;
  }
  getMyAppreciationSummary(vemployeeID, Previousyear) {
    return this.apiService.getParameters(`/Appreciation/GetMyAppreciationSummary?employeeID=${vemployeeID}&year=${Previousyear}`)
    .then(data => {
      return data;
    })
    ;
  }


  GetAppreciationYearList()
  {
    return this.apiService.get('/Appreciation/GetYearList')
    .then(data => {
      return data;
    });
  }

  GetAppreciationsProvidedList(empid)
  {
    return this.apiService.getParam('/Appreciation/GetAppreciationsProvidedByMe?employeeID',empid)
    .then(data => {
      return data;
    });
  }

  GetTopPerformanceList(Previousyear)
  {
    return this.apiService.getParameters(`/Appreciation/GetTopPerformancePreview?year=${Previousyear}`)
    .then(data => {
      return data;
    });
  }

  GetTopPerformanceListByCategory(Previousyear,categoryId)
  {
    return this.apiService.getParameters(`/Appreciation/GetAppreciationByCategoryID?year=${Previousyear}&CategoryID=${categoryId}`)
    .then(data => {
      return data;
    });
  }

  GetEmployeeAppreciationsByID(Previousyear,categoryId,employeeId)
  {
    return this.apiService.getParameters(`/Appreciation/GetEmployeeAppreciationsByID?year=${Previousyear}&CategoryID=${categoryId}&EmployeeID=${employeeId}`)
    .then(data => {
      return data;
    });
  }
  
}

