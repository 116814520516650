import {inject} from 'aurelia-dependency-injection';
import { UserAgentApplication } from 'msal';
import { msalConfig } from '../../authentication/msal-config';
import { ApiService } from './apiservice';
import {Router} from 'aurelia-router';
import { LoginService } from './loginservice';

@inject(ApiService,Router,LoginService)
export class MsalService {

  constructor(ApiService,Router,LoginService) {
    this.userAgentApplication = new UserAgentApplication(msalConfig);
    this.apiService = ApiService;
    this.router = Router;
    this.loginService = LoginService;
    this.user = null;
    this.accessToken = null;
  }

  GetEmployeeMsalDetails(email) {
    return this.apiService.getParam('/Employee/GetDetailsForO365?EmailID',email)
        .then(data => {
          this.loginService.setAuth(data); 
            return data;
        });
}

  async signIn() {
    try {
      const response = await this.userAgentApplication.loginPopup();
      this.userEmail = response.account.userName;
      this.GetEmployeeMsalDetails(this.userEmail).then(data => {
        if(data.EmployeeID > 0){
          window.localStorage['UserName'] = data.UserName;
          window.localStorage['Password'] = data.Password;
          window.localStorage['RememberMe'] = data.RememberMe;
          window.localStorage['EmployeeClass'] = data.EmployeeClass;
          window.localStorage['MsalService'] = true;
          this.router.navigateToRoute('dashboard');
        }    
        else{ 
          console.error(`Invalid credentials: Employee = ${data.EmployeeID.length}`);                   
        }
      })
      //this.accessToken = response.accessToken;
      return true;
    } catch (error) {
      console.error('Sign-in error:', error);
      return false;
    }
  }

  signOut() {
    this.userAgentApplication.logout();
    this.loginService.purgeAuth();
    this.user = null;
    this.accessToken = null;
  }
}
