// session-timeout-service.js
import { inject } from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';
import { ConfirmationDialog } from "../../components/sesssionconfirmation/confirmationcomponent";
import { Router } from 'aurelia-router';

@inject(Router, DialogService,DialogController)
export class SessionTimeoutService {
    constructor(router, dialogService,controller) {
        this.router = router;
        this.dialogService = dialogService;
        this.inactivityTimeout = 15 * 60 * 1000; // 15 minutes in milliseconds
        this.timer = null;
        this.isActive = false;
        this.isPopupOpen = false;
        this.controller = controller;
        this.sessionIdentifier = 'unique_session_identifier'; // Unique identifier for the session
        // Routes to exclude from session timeout behavior
        this.excludedRoutes = ['login', 'feedback', 'forgotpassword'];
        // Check if the session is still active when the service is initialized
        if (!this.isSessionActive() && !this.isExcludedRoute()) {
        }
        this.startTimer();
    }
    isSessionActive() {
        // Check if the session identifier exists in local storage
        return localStorage.getItem(this.sessionIdentifier) !== null;
    }
    isExcludedRoute() {
        // Check if the current route is in the excludedRoutes array
        const currentRoute = this.router.currentInstruction ? this.router.currentInstruction.config.name : '';
        return this.excludedRoutes.includes(currentRoute);
    }
    startTimer() {
        this.timer = setTimeout(async () => {
            if (!this.isPopupOpen && !this.isSessionActive() && !this.isExcludedRoute()) {
                // Show the confirmation dialog only if the popup is not open, the session has expired,
                // and the current route is not in the excludedRoutes
                this.isPopupOpen = true;
                const result = await this.dialogService.open({
                    viewModel: ConfirmationDialog,
                    model: 'Your session is expired. Please login again',
                }).whenClosed();
                this.isPopupOpen = false;
                // If the user confirms, redirect to the login page
                if (!result.wasCancelled) {
                    this.router.navigateToRoute('login');
                    window.location.reload();
                }
            }
        }, this.inactivityTimeout);
    }
    activateSessionTracking() {
        this.isActive = true;
        this.startTimer();
        // Store the session identifier in local storage
        localStorage.setItem(this.sessionIdentifier, 'active');
    }
    resetTimer() {
        // Reset the timer when there is user activity
        clearTimeout(this.timer);
        this.startTimer();
    }
    

}
