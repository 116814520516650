import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';
import {JwtService} from './jwtservice';
import {User} from '../models/user';
import {SharedState} from '../state/sharedstate';
import { data } from 'jquery';
import {Router} from 'aurelia-router';
@inject(ApiService, JwtService, SharedState,Router)
export class LoginService {
  
  constructor(apiService, jwtService, sharedState,router) {
    this.apiService = apiService;
    this.jwtService = jwtService;
    this.sharedState = sharedState;
    this.router = router;
  }
  

  
  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate() {
    if (this.jwtService.getToken()) {
      this.apiService.get('/Employee/GetEmployeeByTokeId')
        .then(data =>
          {
          if(data.Token ==null)
          {
            this.router.navigateToRoute('login');
          }
          else{
          this.setAuth(data)
    }})
       
    } else {
    
      this.purgeAuth();
      this.router.navigateToRoute('login');
    }
  }
  
  setAuth(user) { 
    if(user.Token != null)
    {
      this.jwtService.saveToken(user.Token);
      this.jwtService.saveEmployeeId(user.EmployeeID);
      this.jwtService.saveLocationId(user.LocationID);
      this.jwtService.saveEmployeeClass(user.EmployeeClass);
      this.sharedState.currentUser = user;
      this.sharedState.isAuthenticated = true;
    }
  }
  
  purgeAuth() {   
    this.jwtService.destroyToken();
    this.jwtService.removeEmployeeId();
    this.jwtService.removeLocationId();
    this.jwtService.removeEmployeeClass();
    this.jwtService.removeService();
    this.sharedState.currentUser = new User();
    this.sharedState.isAuthenticated = false;
  }
  
  attemptAuth(type, credentials) {
    
    return this.apiService.post('/Employee/PostValidEmployee', credentials)
      .then(data => {    
          this.setAuth(data);
          return data;     
        
      });
  }
  
  update(user) {
    return this.apiService.put('/Employee', { user })
      .then(data => {
        this.sharedState.currentUser = data;
        return data.user;
      });
    
  }

  bindImages()
  {
    return this.apiService.get('/Login/GetLoginPageImages')
        .then(data =>
        {
            return data;
    });
  }

  NeedHelp(type, fpassword) {
    return this.apiService.post('/Employee/PostForgotLogin', fpassword)    
      .then(data => {    
        return data;     
       });
     }

  EmployeeDeparmentName(){
    return this.apiService.get('/Employee/GetAllDepartmentsForDropdown')
    .then(data => {
    return data;
      });
  }

  EmployeesNameDropDown()
  {
    return this.apiService.get('/Employee/GetEmployeeNamesForDropdown')
    .then(data => 
      { 
        return data;  
      });
  }
}
