import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';



@inject(ApiService)
export class HeaderService {
  
  constructor(apiService) {
    this.apiService = apiService; 
  }

  dynamicHeader(empid) 
  {
     return this.apiService.getParam('/Employee/GetHeaderEmployeeDetails?EmployeeId', empid)
      .then(data => 
        { 
          return data;  
        }); 
  }
}
