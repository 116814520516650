export const constants = {
  
    mycompoffsitemapid:156,
    employeemastersitemapid:191,
    employeetimeoffdetailssitemapid:148,
    employeetimeoffsummarysitemapid:150,
    holidaylistsitemapid:155,
    mytimeoffdetailssitemapid:137,
    mytimeoffsummarysitemapid:149,
    myassetsitemapid:150,
    compoffworkflowcategoryid:12,
    leaveworkflowcategoryid:6,
    CreditLeaveApprovalWorkflowCategoryId:8,
    assetrequestworkflowcategoryid:29,
    attendanceworkflowcategoryid:14,
    assetsitemapid: 426,
    assetrequestionlist:420,
    myattendancesitemapid:274,
    announcementsSitemapId:478,
    policiessitemapId:129,
    permanentEmployeeClassID:1,
    applytimeoffSiteMapID:680,
    hierarchychartSiteMapID:1226,
    myfeedbackSiteMapID:488,
    ptoannouncementsitemapid:1395,
    employeesalaryrevisionlistsitemapid:1194,
    mykrasheetsitemapid :1155,
    performancereviewinitiationsitemapid :1150,
    employeependingtimeoffadjustments:354,
    ConfirmationReviewSiteMapid :173,
    MyPeriodicReviewSiteMapid:1299,
    PeerReviewSiteMapid:175,
    PeriodicReviewSiteMapid:181,
    employeeperformancelistsitemapid:840,

  }
