import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';
import { data } from 'jquery';


@inject(ApiService)
export class EmployeeMasterService {
  
  constructor(apiService) {
    this.apiService = apiService;
   
  }
  // getting data to dropdown
  GetEmployeeNames(vStatusCode) 
  {
     return this.apiService.getParameters(`/Employee/GetEmployeeMasterDetailsDropdown?StatusCode=${vStatusCode}`)
      .then(data => 
        { 
          // console.log(data);
          return data;  
        }); 
  }

  GetAllEmployeeDetails(vStatusCode,empId) 
  {
     return this.apiService.getParameters(`/Employee/GetEmployeeMasterDetails?StatusCode=${vStatusCode}&EmployeeID=${empId}`)
      .then(data => 
        { 
          return data;  
        }); 
  }


  DeletePersonDetails(PersonId,empId){
    //console.log(PersonId);
    return this.apiService.post(`/Employee/DeletePersonDetails?PersonId=${PersonId}&EmployeeID=${empId}`)
    .then(data => {
      return data;
    })
  }

  GetEmployeeDetailsExportcsv(){
   return this.apiService.get('/Employee/Generate')
   .then(data => {
    return data;
  }) 
}

ActivatePersonDetails(PersonId,empId){
 // console.log(PersonId);
  return this.apiService.post(`/Employee/ActivatePersonDetails?PersonId=${PersonId}&EmployeeID=${empId}`)
  .then(data => {
    return data;
  })
}

}
