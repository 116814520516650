import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';

@inject(ApiService)
export class ProfileService {
  
  constructor(apiService) {
    this.apiService = apiService; 
  }


  EmployeeDetails(empid) 
  {
     return this.apiService.getParam('/Employee/GeneralInformationDetails?EmployeeId', empid)
      .then(data => 
        { 
          return data;  
        }); 
  }

    EmployeePersonalDetails(empid)
    {
        return this.apiService.getParam('/Employee/PersonalInformationDetails?EmployeeID', empid)
            .then(data =>
            {
                return data;
            });
    }

    EmployeePersonalDetailsLanguage()
    {
        return this.apiService.get('/Employee/GetCompetencys')
            .then(data =>
            {
                return data;
            });
    }
    UpdatePersonalDetailsLanguage(EmployeeID,PersonLanguageID)
    {
        return this.apiService.getParameters(`/Employee/UpdatePersonLanguage?EmployeeID=${EmployeeID}&PersonLanguageID=${PersonLanguageID}`)
            .then(data =>
            {
                return data;
            });
    }

    EmployeePersonalDetailsGetBloodGroupsForDropdown()
    {
        return this.apiService.get('/Employee/GetBloodGroupsForDropdown')
            .then(data =>
            {
                return data;
            });
    }
    EmployeePersonalDetailsGetMaritalStatusForDropdown()
    {
        return this.apiService.get('/Employee/GetMaritalStatusForDropdown')
            .then(data =>
            {
                return data;
            });
    }
    EmployeePersonalDetailsGetEthinicRacesForDropdown()
    {
        return this.apiService.get('/Employee/GetEthinicRacesForDropdown')
            .then(data =>
            {
                return data;
            });
    }

    EmployeePersonalDetailsGetNationalitiesForDropdown()
    {
        return this.apiService.get('/Employee/GetNationalitiesForDropdown')
            .then(data =>
            {
                return data;
            });
    }

    EmployeeFamilyRelationship()
    {
        return this.apiService.get('/Employee/GetAllRelationshipsForDropdown')
            .then(data =>
            {
                return data;
            });
    }

    EmployeeFamilyLifeeventtypes()
    {
        return this.apiService.get('/Employee/GetAllLifeeventtypesForDropdown')
            .then(data =>
            {
                return data;
            });
    }
    EmployeeNamesForDropdown()
    {
        return this.apiService.get('/Employee/GetEmployeeNamesForDropdown')
            .then(data =>
            {
                return data;
            });
    }
    GetEmployeeNamesForGrid(empid)
    {
        return this.apiService.getParam('/Employee/GetEmployeeGridBinding?EmployeeID', empid)
            .then(data =>
            {
                return data;
            });
    }


    EmployeeLanguageSave(data)
    {
        return this.apiService.post('/Employee/PostPersonLanguage',data)
            .then(data =>
            {

                return data;
            });
    }


    EmployeeFamilyMemberSave(data)
    {
        return this.apiService.post('/Employee/PostPersonDependant',data)
            .then(data =>
            {

                return data;
            });
    }

    GetFamilyMember(id)
    {
        return this.apiService.getParam('/Employee/GetPersonFamilyMembers?EmployeeID',id)
            .then(data =>
            {
                return data;
            });
    }

    GetEmployeeLanguage(id)
    {
        return this.apiService.getParam('/Employee/GetPersonLanguages?EmployeeID',id)
            .then(data =>
            {
                return data;
            });
    }
    DeleteEmployeeLanguage(PersonLanguageID,EmployeeID)
    {
        return this.apiService.post(`/Employee/DeletePersonLanguage?PersonLanguageID=${PersonLanguageID}&EmployeeID=${EmployeeID}`)
            .then(data =>
            {
                return data;
            });
    }

    DeleteEmployeeFamily(PersonDependantID,EmployeeID)
    {
        return this.apiService.post(`/Employee/DeletePersonDependant?PersonDependantID=${PersonDependantID}&EmployeeID=${EmployeeID}`)
            .then(data =>
            {
                return data;
            });
    }
    SavePostPersonInformation(data){
        return this.apiService.post('/Employee/PostPersonInformation',data)
            .then(data => {

                return data;
            });
    }

    /**
     * Work Experience fetch API
     * @param {*} EmpID
     * @returns
     */
    GetWorkExperience(EmpID){
      return this.apiService.getParam('/Employee/GetPersonWorkExperiences?EmployeeID',EmpID)
      .then(data =>{
        return data;
      })
    }
    /**
     * Work Experience save API
     * @param {*} data
     * @returns
     */
    SaveWorkExperience(data){
      return this.apiService.post('/Employee/PostPersonWorkExperience',data)
      .then(data => {
        return data;
      })
    }
    GetPreviousWorkExperience(data){
      return this.apiService.post('/Employee/GetPreviousExperiance',data)
      .then(data => {
        return data;
      })
    }
    //GET api/Employee/GetExperienceCalculation?fromdates={fromdates}&todates={todates}
    GetExperience(fromdates,todates){
      return this.apiService.getParameters(`/Employee/GetExperienceCalculation?fromdates=${fromdates}&todates=${todates}`)
      .then(data =>{
        return data;
      })
    }
    /**
     * Work Experience Delete API
     * @param {*} EmpID
     * @param {*} PersonWorkExperienceID
     * @returns
     */
    DeleteWorkExperience(PersonWorkExperienceID,EmpID){
      return this.apiService.post(`/Employee/DeletePersonWorkExperience?PersonWorkExperienceID=${PersonWorkExperienceID}&EmployeeID=${EmpID}`)
      .then(data => {
        return data;
      })
    }
    /**
     * Employee Skills fetch API
     * @param {*} EmpID
     * @returns
     */
    GetEmployeeSkills(EmpID){
      return this.apiService.getParam('/Employee/GetPersonSkills?EmployeeID',EmpID)
      .then(data => {
        return data;
      })
    }

    /**
     * Empleyee Skills Rating Dropdown API
     * @returns {*}
     * @constructor
     */
    GetSkillsRatingDropdown(){
        return this.apiService.get('/Employee/GetSkillsRatings')
            .then(data => {
                return data;
            })
    }

    /**
     * Employee Skill Dropdown API
     * @returns {*}
     * @constructor
     */


    GetSkillsDropdown(){
        return this.apiService.get('/Employee/GetAllSkills')
            .then(data => {
                return data;
            })
    }


    GetCertificationDropdown(){
        return this.apiService.get('/Employee/GetAllCertificationNames')
            .then(data => {
                return data;
            })
    }



    /**
     * Employee Skills save API
     * @param {*} data
     * @returns
     */
    SaveEmployeeSkills(data){
      return this.apiService.post('/Employee/PostPersonskill',data)
      .then(data => {
        return data;
      })
    }
    /**
     * Employee Skills delete API
     * @param {*} EmpID
     * @param {*} PersonSkillID
     * @returns
     */
    DeleteEmployeeSkills(PersonSkillID,EmpID){
      return this.apiService.post(`/Employee/DeletePersonSkill?PersonSkillID=${PersonSkillID}&EmployeeID=${EmpID}`)
      .then(data => {
        return data;
      })
    }
    SavePostPersonPhotoPath(data){
        return this.apiService.post('/Employee/PostPersonPhotoPath',data)
            .then(data => {
                return data;
            });
    }

    EducationDropdown(){
        return this.apiService.get('/Employee/GetEducationTypesForDropdown')
            .then(data => {
                return data;
            });
    }


    EducationGetScore(){
        return this.apiService.get('/Employee/GetScoresForDropdown')
            .then(data => {
                return data;
            });
    }


    Educationdetailssave(data){
        return this.apiService.post('/Employee/PostPersonAcademicDetail',data)
            .then(data => {

                return data;
            });

    }
    Educationdetailsoverlapsave(data){
        return this.apiService.post('/Employee/GetPreviousEducation',data)
            .then(data => {

                return data;
            });

    }

    GetAllEducation(){
        return this.apiService.get('/Employee/GetAllEducation')
            .then(data => {
                return data;
            });
    }

    GetUniversity(){
        return this.apiService.get('/Employee/GetAllUniversity').then(data =>{
            return data;
        } );
    }
    GetCurrencysymbol(){
        return this.apiService.get('/Employee/GetLocalizationSettings').then(data =>{
            return data;
        } );
    }
    GetAcademicDetails(empid){
        return this.apiService.getParam('/Employee/GetPersonAcademicDetails?EmployeeID',empid).then(data =>{
            return data;
        } );
    }
    DeleteAcademicDetails(PersonEducationID,EmployeeID)
    {
        return this.apiService.post(`/Employee/DeletePersonAcademicDetail?PersonEducationID=${PersonEducationID}&EmployeeID=${EmployeeID}`)
            .then(data =>
            {
                return data;
            });
    }

        //Documents
        GetNotificationFrequenciesDropdown(){
          return this.apiService.get('/Employee/GetNotificationFrequenciesForDropdown')
            .then(data => 
              {
                return data;
              });
        }
    
        GetDocumentTypesDropdown(){
          return this.apiService.get('/Employee/GetDocumentTypesForDropdown')
            .then(data => 
              {
                return data;
              });
        }
    
        GetStatesDropdown(){
          return this.apiService.get('/Employee/GetStatesForDropdown')
          .then(data =>{
            return data;
          });
        }
    
        GetCountryDropdown(){
          return this.apiService.get('/Employee/GetCountriesForDropdown')
          .then(data => {
            return data;
          });
        }
    
        GetNotificationTypesDropdown(){
          return this.apiService.get('/Employee/GetNotificationTypesForDropdown')
          .then(data => {
            return data;
          });
        }

        DocumentAvoidDuplicate(data){
          return this.apiService.post('/Employee/GetPersonDocumentExist',data)
              .then(data => {
                  return data;
              });
      }
    
        GetEmployeeDocuments(EmployeeID){
          return this.apiService.getParam('/Employee/GetPersonDocuments?EmployeeID',EmployeeID)
          .then(data => {
            return data;
          });
        }
    
        SaveDocument(data){
          return this.apiService.post('/Employee/PostPersonDocument',data)
          .then(response => {
            return response;
          })
        }
    
        DeleteEmployeeDocument(DocumentID,EmpID){
          return this.apiService.post(`/Employee/DeletePersonDocument?EmployeeDocumentID=${DocumentID}&EmployeeID=${EmpID}`)
          .then(data => {
            return data;
          })
        }

        GetDocumentHistory(EmpDocID){
          return this.apiService.getParam('/Employee/GetEmployeeDocumentsHistory?EmployeeDocumentID',EmpDocID)
          .then(data => {
            return data;
          })
        }

        GetDocumentFieldHistory(EmpId){
          return this.apiService.getParam('/Employee/GetEmployeeDocumentsFieldHistory?EmployeeDocumentID',EmpId)
          .then(data => {
            return data;
          })
        }

    //contact details API's


    ContactTypeDropdown(){
        return this.apiService.get('/Employee/GetContactTypes')
            .then(data => {
                return data;
            });
    }

    CountryCodesDropdown(){
        return this.apiService.get('/Employee/GetCountryCodes')
            .then(data => {
                return data;
            });
    }

    CountryNamesDropdown(){
        return this.apiService.get('/Employee/GetCountriesForDropdown')
            .then(data => {
                return data;
            });
    }
    CountryStatesDropdown(){
        return this.apiService.get('/Employee/GetStatesForDropdown')
            .then(data => {
                return data;
            });
    }

    ContactDetailsSave(data){
        return this.apiService.post('/Employee/PostPersonContactDetails',data)
            .then(data => {

                return data;
            });

    }

    AddressDetailsSave(data){
        return this.apiService.post('/Employee/PostPersonAddressDetails',data)
            .then(data => {
                return data;
            })
    }

    GetContactDetails(EmployeeID){
        return this.apiService.getParam('/Employee/GetPersonContactDetails?EmployeeID',EmployeeID)
            .then(data => {
                return data;
            });

    }

    GetEmergencyContactDetails(EmployeeID){
        return this.apiService.getParam('/Employee/GetPersonEmergencyContacts?EmployeeID',EmployeeID)
            .then(data => {
                return data;
            });

    }

    GetEmergencyContactHistory(EmployeeID){
        return this.apiService.getParam('/Employee/GetPersonEmergencyContactsHistory?EmployeeID',EmployeeID)
            .then(data => {

                return data;
            });

    }

    EditEmployeeAddress(ContactTypeId,PersonContactTypeId,PersonId){
        return this.apiService.getParameters(`/Employee/GetPersonAddressDetailsByContactTypeID?ContactTypeId=${ContactTypeId}&PersonContactTypeId=${PersonContactTypeId}&PersonId=${PersonId}`)
            .then(data => {

                return data;
            });

    }

    EditEmployeeContact(ContactTypeId,PersonContactTypeId,PersonId){
        return this.apiService.getParameters(`/Employee/GetPersonContactDetailsByContactTypeID?ContactTypeId=${ContactTypeId}&PersonContactTypeId=${PersonContactTypeId}&PersonId=${PersonId}`)
            .then(data => {

                return data;
            });

    }

    DeleteEmployeeContact(ContactTypeId,PersonId,EmployeeID){
        return this.apiService.post(`/Employee/DeletePersonContactDetail?ContactTypeId=${ContactTypeId}&PersonId=${PersonId}&EmployeeID=${EmployeeID}`)
            .then(data => {

                return data;
            });

    }

    SaveEmergencyContact(data){
        return this.apiService.post('/Employee/PostPersonEmergencyContact',data)
            .then(data => {
                return data;
            })
    }
    DeleteEmergencyContact(PersonEmergencyContactID,EmployeeID){
        return this.apiService.post(`/Employee/DeletePersonEmergencyContact?PersonEmergencyContactID=${PersonEmergencyContactID}&EmployeeID=${EmployeeID}`)
            .then(data => {

                return data;
            });

    }
    //*Contact*//

    AgreementSave(data){
        return this.apiService.post('/Employee/PostPersonAgreement',data)
            .then(data => {
                return data;
            });

    }
    AgreementNameAvoidDuplicate(data){
        return this.apiService.post('/Employee/GetPersonAgreementExist',data)
            .then(data => {
                return data;
            });

    }


    LanguageNameAvoidDuplicate(data){
        return this.apiService.post('/Employee/GetPersonLanguageExist',data)
            .then(data => {
                return data;
            });

    }


    EmployeeNameAvoidDuplicate(data){
        return this.apiService.post('/Employee/GetPersonDependentExist',data)
            .then(data => {
                return data;
            });

    }

    LanguageAvoidDuplicate(data){
        return this.apiService.post('/Employee/GetPersonLanguageExist',data)
            .then(data => {
                return data;
            });

    }
    SkillAvoidDuplicate(data){
        return this.apiService.post('/Employee/GetPersonSkillExist',data)
            .then(data => {
                return data;
            });

    }
    

    EmergencyContactAvoidDuplicate(data){
        return this.apiService.post('/Employee/GetPersonEmergencyContactExist',data)
            .then(data => {
                return data;
            });

    }

    GetAgreementdetails(empid){
        return this.apiService.getParam('/Employee/GetPersonAgreement?EmployeeID',empid).then(data =>{
            return data;
        } );
    }
    DeleteAggrementDetails(PersonAgreementId,EmployeeID){
        return this.apiService.post(`/Employee/DeletePersonAgreement?PersonAgreementId=${PersonAgreementId}&EmployeeID=${EmployeeID}`)
            .then(data =>
            {

                return data;
            })
    }
    Orientationtabledata(empid){
        return this.apiService.getParam('/Employee/GetEmployeeOrientation?EmployeeID', empid).then(data =>{
            return data;
        } )
    }

    OrientationViewData(ProgramID){

        return this.apiService.getParam('/Employee/GetViewOrientation?inductionProgramListID',ProgramID).then(data =>{

            return data;
        })
    }

    OrientationLocation(ProgramID){
        return this.apiService.getParam('/Employee/GetOrientationLocation?inductionProgramListID',ProgramID).then(data =>{

            return data;
        })
    }

    //CTC Revision APIs//

    GetEmployeeCTCData(EmployeeID){
        return this.apiService.getParam('/Employee/GetPersonCTCRevisions?EmployeeID',EmployeeID)
            .then(data => {
                return data;
            });
    }
    EmployeeDropdown(){
        return this.apiService.get('/Employee/GetEmployeeNamesForDropdown')
            .then(data => {
                return data;
            });
    }

    GetEmployeesForAdd(empid){
        return this.apiService.getParam('/Employee/GetEmployeeGridBinding?EmployeeID',empid)
            .then(data =>
            {
                return data;
            });
    }
    DepartmentDropdown(){
        return this.apiService.get('/Employee/GetAllDepartmentsForDropdown')
            .then(data => {
                return data;
            });
    }
    PositionDropdown(){
        return this.apiService.get('/Employee/GetAllPositionsForDropdown')
            .then(data => {
                return data;
            });
    }
    PositionLevelDropdown(){
        return this.apiService.get('/Employee/GetAllPositionLevelsForDropdown')
            .then(data => {
                return data;
            });
    }
    DeleteEmployeeCTCData(SalaryRevisionID,EmployeeID){
        return this.apiService.post(`/Employee/DeletePersonCTCRevision?SalaryRevisionID=${SalaryRevisionID}&EmployeeID=${EmployeeID}`)
            .then(data => {

                return data;
            });

    }

    SaveCTCData(data){
        return this.apiService.post('/Employee/PostPersonCTCRevision',data)
            .then(data => {
                return data;
            })
    }

    // CTC END //

 //User Defined tab API's   

 GetUserDefinedFields(EmployeeID){
  return this.apiService.getParam('/Employee/GetPersonUserDefinedFields?EmployeeID',EmployeeID)
  .then(response => {
    return response;
  });
}

SaveUserDefinedFields(data){
   return this.apiService.post('/Employee/PostPersonUserDefinedField',data) 
   .then(response => {  
   return response; 
   }); 
  }

  //Employee Letters

GetEmployeeLetters(EmployeeID){
  return this.apiService.getParam('/Employee/GetEmployeeLetters?EmployeeID',EmployeeID)
  .then(response => {
    return response;
  });
}
//TemplateName, Subject, Content, Path, GeneratedDT, SendAcknowledgementEmpIND,  DisciplinaryActionID
SaveEmployeeLetter(data){
  return this.apiService.post('/Employee/PostEmployeeLetters',data) 
  .then(response => {  
  return response; 
  }); 
 }

 GetPreviewLetterData(data){
  return this.apiService.post('/Employee/PreviewEmployeeLetters',data) 
  .then(response => {  
  return response; 
  }); 
 }

 GetEmployeeLetterCommunicationHistory(EmployeeID){
  return this.apiService.getParam('/Employee/GetEmployeeLetterCommunicationHistorys?EmployeeID',EmployeeID) 
  .then(response => {
  return response; 
  }); 
 }

 GetPostEmployeeLetter(data){
  return this.apiService.post('/Employee/PostEmployeeLetterCommunicationHistory',data) 
  .then(response => {
  return response; 
  }); 
 }

 PostUploadAck(data){
  return this.apiService.post('/Employee/PostEmployeeLetterUploadAcknowledgement',data) 
  .then(response => {
  return response; 
  }); 
 }

 EditUploadAck(CommunicationHistoryID){
  return this.apiService.getParam('/Employee/GetEmployeeLetterCommunicationHistoryAcknowledgement?EmployeeLettercommunicationhistoryID',CommunicationHistoryID)
  .then(response => {
    return response;
  });
 }

 GetLetterSendModes(){
  return this.apiService.get('/Employee/GetEmployeeLetterSendModes') 
  .then(response => {
    return response; 
  });
 }

 GetDefaultDataForSendModes(EmpID){
  return this.apiService.getParam('/Employee/GetaddrssPersonDetailsByPersonId?EmployeeID',EmpID)
  .then(response => {
    return response;
  });
 }

 GetAllTemplates(){
  return this.apiService.get('/Employee/GetAllEmployeeLetterTemplateName') 
  .then(response => {
    return response; 
  });
 }

 GetTemplates(){
  return this.apiService.get('/Employee/GetLetterTemplatesForDropDown') 
  .then(response => {
    return response; 
  });
 }

 DeleteEmployeeLetter(letterID,EmployeeID){
  return this.apiService.post(`/Employee/DeleteEmployeeLetters?EmployeeLetterID=${letterID}&EmployeeID=${EmployeeID}`) 
  .then(response => {  
  return response; 
  });
 }

}
