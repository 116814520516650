import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';
import {DialogService} from 'aurelia-dialog';

@inject(ApiService,DialogService)
export class LeavemanagementService {
    constructor(apiService,DialogService) {
        this.apiService = apiService;
        this.dialogService = DialogService;
    }
    GetLeaveTypesForDropDown(EmployeeId){
        return this.apiService.getParam('/Leave/GetLeaveTypesForDropDown?EmployeeId',EmployeeId)
            .then(data => {
                return data;
            })
    }
    GetLeaveBalance(LeaveTypeID,EmployeeId){
        return this.apiService.getParameters(`/Leave/GetBalanceLeavesByLeaveTypeId?LeaveTypeID=${LeaveTypeID}&EmployeeId=${EmployeeId}`)
            .then(data => {
                return data;
            })
    }
    EmployeeNamesForDropdown()
    {
        return this.apiService.get('/Employee/GetEmployeeNamesForDropdown')
            .then(data =>
            {
                return data;
            });
    }
    GetEmployeesForAdd(EmployeeID){
        return this.apiService.getParam('/Employee/GetEmployeeGridBinding?EmployeeID',EmployeeID)
            .then(data =>
            {
                return data;
            });
    }
    SaveApplyLeave(data){
        return this.apiService.post('/Leave/PostApplyLeave',data)
            .then(response => {
                return response;
            });
    }
    LocationsForDropdown()
    {
        return this.apiService.get('/Employee/GetAllLocationsForDropdown')
            .then(data =>
            {
                return data;
            });
    }
    YearsForDropdown()
    {
        return this.apiService.get('/Employee/GetYearForDropdown')
            .then(data =>
            {
                return data;
            });
    }
    GetYearsDropdown()
    {
        return this.apiService.get('/Employee/GetYearsDropdown')
            .then(data =>
            {
                return data;
            });
    }
    GetHolidayList(LocationID,Year,EmployeeID){
        return this.apiService.getParameters(`/Leave/GetHolidays?LocationID=${LocationID}&Year=${Year}&EmployeeID=${EmployeeID}`)
            .then(data => {
                return data;
            })
    }
    GetMaxNoofLeaves(LocationID,Year,EmployeeID){
        return this.apiService.getParameters(`/Leave/GetMaxNoofLeaves?leaveTypeId=${LocationID}&EmployeeID=${Year}&TotalTimeoffs=${EmployeeID}`)
            .then(data => {
                return data;
            })
    }
    GetFeatureHoliday(){
        return this.apiService.get('/Leave/GetFeatureHolidays')
            .then(data => {
                return data;
            })
    }
    GetNoOfDays(data){
        return this.apiService.post('/Leave/GetNoofDaysleave',data)
            .then(data => {
                return data;
            })
    }
    GetMyLeaveBalance(EmployeeID)
    {
        return this.apiService.getParam('/Leave/GetMyLeaveSummary?EmployeeId',EmployeeID)
            .then(data =>
            {
                return data;
            });
    }
    GetEmployeePTOAnnouncemnt(data)
    {
        return this.apiService.post('/Organization/GetEmployeePTOAnnouncemnt?EmployeeId',data)
            .then(data =>
            {
                return data;
            });
    }
    DepartmentsForDropdown()
   {
     return this.apiService.get('/Employee/GetAllDepartmentsForDropdown')
      .then(data => 
        { 
          return data;  
        });
    }

    GetEmployeeClassDropdown()
    {
      return this.apiService.get('/Leave/GetEmployeeClassDropdown')
       .then(data => 
         { 
           return data;  
         });
     }

    GetEmployeemasterDetails() {
        const defaultEmpId = '4'
         return this.apiService.getParam('/Employee/GetEmployeeMasterDetails?StatusCode=ACT&EmployeeID',defaultEmpId)
          .then(data => 
            { 
              return data;  
            }); 
      }
    
    GetTimeOffSummary(data)
    {
        return this.apiService.post('/Leave/GetMyLeaveDetails?EmployeeId',data)
            .then(data =>
            {
                return data;
            });
    }

    SaveCreditLeave(data){
        return this.apiService.post('/Leave/PostCreditLeave',data)
        .then(data => {
          return data;
        })
      }

    GetTimeOffAdjustments(data)
    {
        return this.apiService.post('/Leave/GetTimeOffAdjustments?EmployeeId',data)
            .then(data =>
            {
                return data;
            });
    }
    GetPersonalLeaveDetails(data)
    {
        return this.apiService.post('/Leave/GetPersonalLeaveDetails?EmployeeId',data)
            .then(data =>
            {
                return data;
            });
    }
    GetLeaveApplicationDetails(LeaveApplicationID){
        return this.apiService.getParam('/Leave/GetLeaveApplicationDetails?LeaveApplicationID',LeaveApplicationID)
            .then(data =>
            {

                return data;
            });
    }
    GetEmployeePeersLeaveNotifyEmails (LeaveApplicationID){
        return this.apiService.getParameters(`/Leave/GetEmplooyeeLeaveNotifyEmails?LeaveApplicationID=${LeaveApplicationID}`)
            .then(data => {
                return data;
            })
    }
     
    GetEmployeeNamesByDepartmentID (DepartmentID){
        return this.apiService.getParam('/Leave/GetEmployeeNamesByDepartmentID?DepartmentID',DepartmentID)
            .then(data => {
                return data;
            })
    }

    GetLeaveDetailsofOtherEmployees(data){
        return this.apiService.post('/Leave/GetOtherLeaveDetails',data)
            .then(data => {
                return data;
            })
    }
    GetLeaveDaywiseSummary(LeaveApplicationID){
        
        return this.apiService.getParameters(`/Leave/GetLeaveDaywiseSummary?LeaveApplicationID=${LeaveApplicationID}`)
            .then(data => {
                return data;
            })
    }
    CancelTimeoffRequest(data){
        return this.apiService.post('/Leave/PostCancelLeave',data)
            .then(data => {
                return data;
            })
    }
    GetAllDepartmentsForDropdown()
    {
        return this.apiService.get('/Employee/GetAllDepartmentsForDropdown')
            .then(data =>
            {
                return data;
            });
    }
    DepartmentDropdown(EmployeeId,StatusCode)
        {
         return this.apiService.getParameters(`/Employee/GetDepartmentsForDropdown?EmployeeId=${EmployeeId}&StatusCode=${StatusCode}`)
        .then(data =>
        {
            return data;
        });
}

GetEmployeeNamesByID(DepartmentID,StatusCode)
{
 return this.apiService.getParameters(`/Leave/GetEmployeeNamesByID?DepartmentID=${DepartmentID}&Statuscode=${StatusCode}`)
.then(data =>
{
    return data;
});
}

GetAllEmployeeNamesDropdown(EmployeeId,StatusCode,DepartmentIds)
{
    return this.apiService.getParameters(`/Employee/GetEmployeesForDropdown?EmployeeId=${EmployeeId}&StatusCode=${StatusCode}&DepartmentIds=${DepartmentIds}`)
        .then(data =>
        {
            return data;
        });
}
    GetHolidayListForGrid()
    {
        return this.apiService.get('/Leave/GeEmployeeHoliday')
            .then(data =>
            {
                return data;
            });
    }
    EmployeeClassDropdown()
    {
        return this.apiService.get('/Leave/GetEmployeeClassesForDropdown')
            .then(data =>
            {
                return data;
            });
    }
    EmployeeDropdown(vStatusCode){
        return this.apiService.getParam('/Employee/GetEmployeeNamesForDropdownSearch?StatusCode',vStatusCode)
            .then(data => {
                return data;
            });
    }
    GetEmployeeTimeOffRequests(data)
    {
        return this.apiService.post('/Leave/GetEmployeeTimeOffRequests?EmployeeId',data)
            .then(data =>

            {
                return data;

            });
    }
    GetEmployeeHolidayLists(data)
    {
        return this.apiService.post('/Leave/GeEmployeeHoliday',data)
            .then(data =>

            {
                return data;
            });
    }
    GeEmployeeHolidays(data)
    {
        return this.apiService.post('/Leave/GeEmployeeHolidays',data)
            .then(data =>

            {
                return data;
            });
    }

    GeHolidaysList(data)
    {
        return this.apiService.post('/Leave/GeHolidaysList',data)
            .then(data =>

            {
                return data;
            });
    }
    ApproveTimeOffRequests(data)
    {
        return this.apiService.post('/Leave/PostApproveLeave',data)
            .then(data =>
            {
                return data;
            });
    }
    GetEmployeesDepartmentDropdown(EmployeeId,StatusCode){
        return this.apiService.getParameters(`/Employee/GetDepartmentsForDropdown?EmployeeId=${EmployeeId}&StatusCode=${StatusCode}`)
            .then(data =>
            {
                return data;
            });
    }
    GetEmployeesNameDropdown(EmployeeId,StatusCode,DepartmentIds){
        return this.apiService.getParameters(`/Employee/GetEmployeesForDropdown?EmployeeId=${EmployeeId}&StatusCode=${StatusCode}&DepartmentIds=${DepartmentIds}`)
            .then(data =>
            {
                return data;
            });
    }
    GetLeaveTypeDropdown(EmployeeId){
        return this.apiService.getParam('/Leave/GetLeaveTypesForDropDown?EmployeeId',EmployeeId)
            .then(data =>
            {
                return data;
            });
    }
    GetEmployeedetailesByEmployeeId(EmployeeId){
        return this.apiService.getParam('/Leave/GetEmployeedetailesByEmployeeId?EmployeeId',EmployeeId)
            .then(data =>
            {
                return data;
            });
    }
    dynamicHeaderLogo(empid)
    {
        return this.apiService.getParam('/Employee/GetHeaderEmployeeDetails?EmployeeId', empid)
            .then(data =>
            {
                return data;
            });
    }
    
    GetOtherLeavedetails(data)
    {
        return this.apiService.post('/Leave/GetOtherLeaveDetails', data)
            .then(data =>
            {
                return data;
            });
    }
    GetPastLeavesData(EmployeeId,LeaveApplicationID)
    {
        return this.apiService.getParameters(`/Leave/GetPastLeaveDetails?EmployeeId=${EmployeeId}&LeaveApplicationID=${LeaveApplicationID}`)
            .then(data =>
            {
                return data;
            });
    }
    SaveAssignLeave(data){
        return this.apiService.post('/Leave/PostAssignLeave',data)
            .then(response => {
                return response;
            });
    }
    GetAdditionalComments(LeaveApplicationID,CategoryId){
        return this.apiService.getParameters(`/Leave/GetAllCommentsByID?DataID=${LeaveApplicationID}&CategoryID=${CategoryId}`)  
        .then(response =>{
                return response;
            })
    }
    GetInstantApprovalDetails(guid,UserId){
        return this.apiService.getParameters(`/WorkFlowInstantApproval/Index?guid=${guid}&UserId=${UserId}`)
            .then(data => {
                return data;
            })
    }
    GetEmployeeTimeOffSummery(details){
        return this.apiService.post('/leave/GetEmployeeTimeOffSummary',details)
            .then(data =>{
                return data;
            })
    }
    GetMyTimeOffSummary(employeeId){
      return this.apiService.getParam('/Leave/GetMyTimeOffSummary?employeeId',employeeId)
      .then(data =>{
          return data;
      });
  }
  GetMyTimeOffSummaryByLeaveTypeId(employeeId,LeaveTypeId){
    return this.apiService.getParameters(`/Leave/GetMyTimeOffSummaryByLeaveTypeId?employeeId=${employeeId}&LeaveTypeId=${LeaveTypeId}`)
    .then(data =>{
        return data;
    });
}
//Assign time off end //
    AddToFavourites(employeeId,sitemapId){
        return this.apiService.getParameters(`/Menu/AddToFavouriteItems?EmployeeID=${employeeId}&SiteMapID=${sitemapId}`)  
        .then(data =>{
            return data;
        });
    }
    GetPersonFavoriteItems(employeeId,sitemapId){
        return this.apiService.getParameters(`/Menu/GetPersonFavoriteItems?EmployeeID=${employeeId}&SiteMapID=${sitemapId}`)  
       .then(data =>{
            return data;
        });
    }
    clarificationDropdown(dataID){
        return this.apiService.getParam('/Employee/GetAllPreviousApprovers?DataID',dataID)
      .then(data =>{
          return data;
      });
    }
    clarifyDropdown(dataID){
        return this.apiService.getParam('/Employee/GetClarifyApprovers?DataID',dataID)
            .then(data =>{
                return data;
            });
    }
    GetMyCompOffDetails(data)
    {
        return this.apiService.post('/Attendance/GetMyCompOffDetails',data)
            .then(data =>
            {
                return data;
            });
    }
    savenewcompoff(data){
        return this.apiService.post('/Attendance/PostCompOff',data)
            .then(data =>
            {
                return data;
            });
    }

    fromDateCompoff(employeeId,date){
        return this.apiService.getParameters(`/Attendance/GetAttendanceByCompOffDate?employeeID=${employeeId}&Date=${date}`)
            .then(data =>
            {
                return data;
            });
    }
    GetViewCompOffDetails(data){
        return this.apiService.getParam('/Attendance/GetViewCompOffDetails?CompOffID', data)
            .then(data =>{
                return data;
            });
    }
    OTCompOffDetails(Fromdate,EmployeeId,compoffId){
        return this.apiService.getParameters(`/Attendance/GetNoofDays?Fromdate=${Fromdate}&EmployeeId=${EmployeeId}&compoffId=${compoffId}`)
            .then(data =>{
                return data;
            });
    }
    cancelcompoff(data){
        return this.apiService.post('/Attendance/PostCancelCompOff',data)
            .then(data =>
            {
                return data;

            });
    }
    AddToRecentItems(employeeId,sitemapId){
        return this.apiService.getParameters(`/Menu/AddToRecentItems?EmployeeID=${employeeId}&SiteMapID=${sitemapId}`)  
        .then(data =>{
            return data;
        });
    }

    IsLeaveWorkflowApproverExist(WorkLocationId)
    {
        return this.apiService.getParam('/Leave/IsLeaveWorkflowApproverExist?WorkLocationId',WorkLocationId)
            .then(data =>
            {
                return data;
            });
    }

    GetMultiuLeaveApplicationDetails(LeaveApplicationIDS){
        return this.apiService.getParam('/Leave/GetLeaveAdjustmentApplicationDetails?LeaveApplicationIDs',LeaveApplicationIDS)
            .then(data =>
            {
                return data;
            });
    }
    ViewTimeOffgrid(statusCode,employeeId,leavetypeid){
        console.log(statusCode,employeeId,leavetypeid)
        return this.apiService.getParameters(`/Leave/GetLeaveApplicationDetailsByYear?StatusCode=${statusCode}&EmployeeId=${employeeId}&LeavetypeId=${leavetypeid}`)
            .then(data =>{

                return data;
            })
    }

    ViewEmployeeTimeOffgrid(statusCode,employeeId,leavetypeid,year){
        //console.log(statusCode,employeeId,);
        return this.apiService.getParameters(`/Leave/GetEmployeeLeaveApplicationDetailsByYear?StatusCode=${statusCode}&EmployeeId=${employeeId}&LeavetypeId=${leavetypeid}&year=${year}`)
            .then(data =>{

                return data;
            })
    }

    GetEmployeeLeaveYearsDropdown(EmployeeID){
        return this.apiService.getParam('/Leave/GetEmployeeLeaveYearsDropdown?EmployeeID',EmployeeID)
            .then(data =>
            {
                return data;
            });
    }
    GetEmployeeLeaveCredits(data)
    {
        return this.apiService.post('/Leave/GetEmployeeLeaveCredits?EmployeeID',data)
            .then(data =>
            {
                return data;
            });
    }

    GetLeaveCreditsEditView(LeaveCreditId,LeaveTypeID,EmployeeID){
        console.log(LeaveCreditId,LeaveTypeID,EmployeeID);
        return this.apiService.getParameters(`/Leave/GetLeaveCreditsEditView?LeaveCreditId=${LeaveCreditId}&LeaveTypeID=${LeaveTypeID}&EmployeeID=${EmployeeID}`)
            .then(data =>{

                return data;
            })
    }

    SaveCreditLeaveEdit(data){
        
        return this.apiService.post('/Leave/PostLeaveCreditsEdit',data)
        .then(data => {
          return data;
        })
      }

      DisplayOnBehalfOf(WorkflowCategoryId,DataId,LoggedInEmployeeId){
      
        return this.apiService.getParameters(`/Leave/DisplayOnBehalfOf?WorkflowCategoryId=${WorkflowCategoryId}&DataId=${DataId}&LoggedInEmployeeId=${LoggedInEmployeeId}`)
            .then(data =>{

                return data;
            })
    }
}
