import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';
@inject(ApiService)
export class FeedbackService {
    constructor(apiService) {
    this.apiService = apiService;
   
  }
  SendtoEmployeesName()
  {
    return this.apiService.get('/FeedbackGrievance/GetSentToEmployeeNamesForDropdown')
      .then(data => 
        { 
          return data;  
        });
  }
  DepartmentsForDropdown()
  {
    return this.apiService.get('/Employee/GetAllDepartmentsForDropdown')
      .then(data => 
        { 
          return data;  
        });
  }
  EmployeeNamesForDropdown()
  {
    return this.apiService.get('/Employee/GetEmployeeNamesForDropdown')
      .then(data => 
        { 
          return data;  
        });
  }
  GetEmployeesForAdd(data){
    return this.apiService.getParam('/Employee/GetEmployeeGridBinding?EmployeeID',data)
      .then(data => 
        { 
          return data;  
        });
  }
  SaveFeedBack(data){
      return this.apiService.post('/FeedbackGrievance/PostAnonymousFeedbackGrievance',data)
    .then(data => {
      return data;
    });
  }
  SaveFeedBackGrievance(data){
    return this.apiService.post('/FeedbackGrievance/PostFeedbackGrievance',data)
  .then(data => {
    return data;
  });
}
ViewFeedbackData(GrievanceID){
        return this.apiService.getParam('/FeedbackGrievance/GetAnonymousFeedbackGrievance?GrievanceID',GrievanceID)
            .then(data =>
            {
                return data;
            });
    }
    ViewFeedback(GrievanceID){
      return this.apiService.getParam('/FeedbackGrievance/GetFeedbackGrievance?GrievanceID',GrievanceID)
          .then(data =>
          {
              return data;
          });
  }
  SaveSupportForm(data){
    return this.apiService.post('Support/PostSupport',data)
    .then(data => {
      return data;
    });
  }
  GetAllEmployeeNamesForDropdown()
  {
    return this.apiService.get('/Employee/GetAllEmployeeNamesForDropdown')
      .then(data => 
        { 
          return data;  
        });
  }
  ViewmyFeedbackData(EmpID,GrievanceID){
    return this.apiService.getParameters(`/FeedbackGrievance/GetGrievancebyID?EmployeeID=${EmpID}&GrievanceID=${GrievanceID}`)
      .then(data =>{
        return data;
        });
  }

  GetmyFeedbackData(EmpID,month,year){
    const status = 'ACT'
    return this.apiService.getParameters(`/FeedbackGrievance/GetMyFeedback?EmployeeID=${EmpID}&StatusCode=${status}&month=${month}&Year=${year}`)
      .then(data =>{
        return data;
        });
  }

  PostReopenData(data){
    return this.apiService.post('/FeedbackGrievance/ReopenForm',data)
    .then(data => {
      return data;
    });
  }
}
