import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';


@inject(ApiService)
export class SidebarService {

    constructor(apiService) {
        this.apiService = apiService;
    }

    DeleteFavoriteItems(employeeId,siteMapID)
    {
        return this.apiService.delete(`/Menu/DeleteFavoriteItems?EmployeeID=${employeeId}&SiteMapID=${siteMapID}`)
            .then(data =>
            {
                return data;
            });
    }
}
