import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';
@inject(ApiService)
export class myAssetsService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    GetMyAssetList(data)
    {
        return this.apiService.getParam('/Asset/GetMyAssetList?EmployeeId',data)
            .then(data =>
            {
                return data;
            });
    }
    GetMyAssetRequisitionList(data)
    {
        return this.apiService.getParam('/Asset/GetMyAssetRequisitionList?EmployeeId',data)
            .then(data =>
            {
                return data;
            });
    }
    AddAssetRequisition(data)
    {
        return this.apiService.post('/Asset/PostAddAssetRequisition',data)
            .then(data =>
            {
                return data;
            });
    }
    GetAssetForDropDown()
    {
        return this.apiService.get('/Asset/GetAssetForDropDown')
            .then(data =>
            {
                return data;
            });
    }
    GetAssetRequisitionDetails(data)
    {
        return this.apiService.getParam('/Asset/GetAssetRequisitionDetailsById?AssetRequisitionID',data)
            .then(data =>
            {
                return data;
            });
    }
    WithdrawnAsset(data){
        return this.apiService.post('/Asset/PostWithdrawnAsset',data)
            .then(data =>
            {
                return data;
            });
    }
    GetAssetReturnForm(employeeId,AssetRequisitionID){
        return this.apiService.getParameters(`/Asset/GetAssetReturnForm?EmployeeId=${employeeId}&AssetRequisitionID=${AssetRequisitionID}`)
            .then(data =>
            {
                return data;
            });
    }
    Returnasset(data){
        return this.apiService.post('/Asset/PostReturnAsset',data)
            .then(data =>
            {
                return data;
            });
    }
    GetViewAssetRequisitionDetails(EmployeeId,AssetRequisitionID){
        return this.apiService.getParameters(`/Asset/GetAssetRequisitionDetails?EmployeeId=${EmployeeId}&AssetRequisitionID=${AssetRequisitionID}`)
            .then(data =>
            {
                return data;

            });
    }
    IsAssetWorkflowApproverExist(WorkLocationId,AssetCategoryID) {

        return this.apiService.getParameters(`/Asset/IsAssetWorkflowApproverExist?WorkLocationId=${WorkLocationId}&AssetCategoryID=${AssetCategoryID}`)
            .then(data => {
                return data;

            });
    }
    Acknowledgementasset(data){
        return this.apiService.post('/Asset/PostAcknowledgementAsset',data)
            .then(data =>
            {
                return data;
            });
    }
}