import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';


@inject(ApiService)
export class KraPerformanceReviewService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    KraCategoryDropdown(ReviewTypeID,EmployeeID){
        return this.apiService.getParameters(`/KRA/GetKRACategoryMaster?ReviewTypeID=${ReviewTypeID}&EmployeeID=${EmployeeID}`)
            .then(data => {

                return data;
            });
    }
    KRARepository(ReviewTypeID,EmployeeID){
        return this.apiService.getParameters(`/KRA/GetPerformanceKRARepository?ReviewTypeID=${ReviewTypeID}&EmployeeID=${EmployeeID}`)
            .then(data => {

                return data;
            });
    }
    PostCreateKRA(data){
        console.log(data);
        return this.apiService.post('/KRA/PostCreateKRA',data)
                .then(data =>
                {
                    return data;
                });
    }
    KRARepositoryPost(KRARepositoryIds,EmployeePerformanceReviewID){
        return this.apiService.post(`/KRA/PostPerformanceKRARepository?KRARepositoryIds=${KRARepositoryIds}&EmployeePerformanceReviewID=${EmployeePerformanceReviewID}`)
            .then(data =>
            {
                return data;
            });
    }
    GetEmployeePerformanceKRADetails(EmployeePerformanceReviewID,ReviewTypeID,EmployeeID)
    {
        return this.apiService.getParameters(`/KRA/GetEmployeePerformanceKRADetails?EmployeePerformanceReviewID=${EmployeePerformanceReviewID}&ReviewTypeID=${ReviewTypeID}&EmployeeID=${EmployeeID}`)
            .then(data =>
            {


                return data;
            });
    }
    DeleteEmployeePerformanceKRA(data){
        return this.apiService.post('/KRA/DeleteEmployeePerformanceKRAByID',data)
            .then(data =>
            {
                return data;
            });
    }
    EditingPostCreateKRA(data){
        return this.apiService.post('/KRA/PostCreateKRA',data)
            .then(data =>
            {
                return data;
            });
    }
    validationkrasubmit(employeePerformanceReviewID){
        return this.apiService.getParameters(`/KRA/GetKRADetailsValidation?EmployeePerformanceReviewID=${employeePerformanceReviewID}`)
            .then(data =>
            {
                return data;
            });
    }
    finalkrasubmit(data){
               return this.apiService.post('/KRA/PostAssign',data)
            .then(data =>
            {
                return data;
            });
    }
    validationEmployeeCategoryKRAExist(EmployeePerformanceKRAID,EmployeePerformanceReviewID,CategoryID,KRATitle){
        return this.apiService.getParameters(`/KRA/GetEmployeeCategoryKRAExist?EmployeePerformanceKRAID=${EmployeePerformanceKRAID}&EmployeePerformanceReviewID=${EmployeePerformanceReviewID}&CategoryID=${CategoryID}&KRATitle=${KRATitle}`)
            .then(data =>
            {
                return data;
            });
    }
    /** employee performance api */
    GetEmployeePerformance(empid){
        return this.apiService.getParam('/KRA/GetEmployeePerformance?EmployeeID',empid)
            .then(data =>
            {
                return data;
            });
    }
    GetExemptEmployeeData(empid){
        return this.apiService.getParam('/KRA/GetExemptEmployee?EmployeePerformanceReviewID',empid)
            .then(data =>
            {
                return data;
            });
    }
    GetEmployeePerformanceFilterData(EmployeeID,DepartmentIds,PositionIds,ReviewType)
    {
        return this.apiService.getParameters(`/KRA/GetEmployeePerformanceFilter?EmployeeID=${EmployeeID}&DepartmentIds=${DepartmentIds}&PositionIds=${PositionIds}&ReviewType=${ReviewType}`)
            .then(data =>
            {
                return data;
            });
    }
    PostBulkAssignData(data){
        return this.apiService.post('/KRA/GetBulkAssignEmployeeList',data)
            .then(data =>
            {

                return data;
            });
    }
    DeleteBulkAssignData(AssignEmployeeByID){
        return this.apiService.post('/KRA/DeleteBulkAssignEmployeeByID',AssignEmployeeByID)
            .then(data =>
            {
                return data;
            });
    }
    PostExemptEmployeeData(data)
    {
        return this.apiService.post('/KRA/PostExemptEmployee',data)
            .then(data =>
            {
                return data;
            });
    }
    PostRevertExemptEmploye(data)
    {
        return this.apiService.post('/KRA/PostRevertExemptEmployee',data)
            .then(data =>
            {
                return data;
            });
    }
   }