export const config = {

  api_url:'https://hrmssso.kensium.com/api',
  img_url: 'https://ithrms.kensium.com/',
  api_img_url: 'https://hrms.kensium.com/',
    grid_PazeSize: 10,
    assetgrid_PazeSize: 5,
    kra_PazeSize: 5,
    salaryGrid_PazeSize: 5,

}
