import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';

@inject(ApiService)
export class ConfirmationService {

    constructor(apiService) {
        this.apiService = apiService;
    }

    DepartmentDropdown(EmployeeId,StatusCode)
    {
     return this.apiService.getParameters(`/Employee/GetDepartmentsForDropdown?EmployeeId=${EmployeeId}&StatusCode=${StatusCode}`)
    .then(data =>
    {
        return data;
    });
}

        EmployeeClassDropdown()
            {
                return this.apiService.get('/Leave/GetEmployeeClassesForDropdown')
                    .then(data =>
                    {
                        return data;
                    });
            }

    GetAllEmployeeDetails(vStatusCode,empId) 
    {
       return this.apiService.getParameters(`/Employee/GetEmployeeMasterDetails?StatusCode=${vStatusCode}&EmployeeID=${empId}`)
        .then(data => 
          { 
            return data;  
          }); 
    }

    GetPersonFavoriteItems(employeeId,sitemapId){
        return this.apiService.getParameters(`/Menu/GetPersonFavoriteItems?EmployeeID=${employeeId}&SiteMapID=${sitemapId}`)  
       .then(data =>{
            return data;
        });
    }
  
    AddToFavourites(employeeId,sitemapId){
        return this.apiService.getParameters(`/Menu/AddToFavouriteItems?EmployeeID=${employeeId}&SiteMapID=${sitemapId}`)  
        .then(data =>{
            return data;
        });
    }

    AddToRecentItems(employeeId,sitemapId){
        return this.apiService.getParameters(`/Menu/AddToRecentItems?EmployeeID=${employeeId}&SiteMapID=${sitemapId}`)  
        .then(data =>{
            return data;
        });
    }

    getEmployeeConfirmationDetails(EmployeeID,FromDate,ToDate){
        return this.apiService.getParameters(`/EmployeeConfirmation/GetEmployeeConfirmationDetails?EmployeeID=${EmployeeID}&FromDate=${FromDate}&ToDate=${ToDate}`)
        .then(data =>{
            return data;
            
        });
    }

    getEmployeeConfirmationList(data)
    {
        return this.apiService.post('/EmployeeConfirmation/PostEmployeeConfirmationDetails',data)
            .then(data =>

            {
                return data;

            });
    }

    getMyPeriodicReviewList(EmployeeId){
        return this.apiService.getParameters(`/EmployeeConfirmation/MyPeriodicReview?EmployeeId=${EmployeeId}`)
        .then(data =>{
            return data;
            
        });
    }

     getEmployeePeriodicReviewList(EmployeeID,FromDate,ToDate){
        return this.apiService.getParameters(`/EmployeeConfirmation/GetEmployeePeriodicReview?EmployeeID=${EmployeeID}&FromDate=${FromDate}&ToDate=${ToDate}`)
        .then(data =>{
            return data;
            
        });
    }

    postEmployeePeriodicReviewList(data)
    {
        return this.apiService.post('/EmployeeConfirmation/PostEmployeePeriodicReview',data)
            .then(data =>
            {
                return data;
            });
    }

    getPeerReviewList(EmployeeID,FromDate,ToDate){
        return this.apiService.getParameters(`/EmployeeConfirmation/GetEmployeePeerReview?EmployeeID=${EmployeeID}&FromDate=${FromDate}&ToDate=${ToDate}`)
        .then(data =>{
            return data;
            
        });
    }

    getLeaveDetails(employeeId){
        return this.apiService.getParameters(`/EmployeeConfirmation/GetLeaveDetails?employeeId=${employeeId}`)
        .then(data =>{
            return data;
            
        });
    }

    getPeriodicReviewView(EmployeeStatusId,EmployeeId){
        return this.apiService.getParameters(`/EmployeeConfirmation/GetConfirmationReviewview?EmployeeStatusId=${EmployeeStatusId}&EmployeeId=${EmployeeId}`)
        .then(data =>{
            return data;
            
        });
    }
    getConfirmationFeedbackView(EmployeeStatusId,EmployeeId){
        return this.apiService.getParameters(`/EmployeeConfirmation/GetConfirmationReviewview?EmployeeStatusId=${EmployeeStatusId}&EmployeeId=${EmployeeId}`)
        .then(data =>{
            return data;
            
        });
    }

}
