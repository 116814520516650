import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';


@inject(ApiService)
export class EmployeeService {
  
  constructor(apiService) {
    this.apiService = apiService;
   
  }

  GetEmployeeDetails(pageNumber) 
  {
     return this.apiService.getParam('/Employee/GetPagedEmployeeDetails?pageNumber',pageNumber)
      .then(data => 
        { 
          //console.log(data);
          return data;  
        }); 
  }

  GetPagedEmployeeDetails(sortby,direction,search,pageNumber){
    return this.apiService.getParameters(`/Employee/GetPagedEmployeeDetails?sortby=${sortby}&direction=${direction}&search=${search}&pageNumber=${pageNumber}`)
      .then(data => 
        { 
          //console.log(data);
          return data;
        });
  }

  SearchEmployeeDetails(id)
  {
    return this.apiService.getParam('/Employee/GeneralInformationDetails?EmployeeID',id)
      .then(response => 
        { 
         // console.log(response);
          return response;  
        });
  }

  GetAllEmployeeDetails() 
  {
     return this.apiService.get('/Employee/GetEmployeeDetails')
      .then(data => 
        { 
          return data;  
        }); 
  }

  VerifyCurrentPassword(Password,EmployeeID){
    return this.apiService.getParameters(`/Employee/GetPasswordValidation?Password=${Password}&EmployeeID=${EmployeeID}`)
    .then(data => 
      { 
        return data;  
      });
  }

  ChangeEmployeePassword(confirmPass)
  {
    return this.apiService.post('/Employee/PostChangePassword',confirmPass)
    .then(data => {
      return data;
    });
  }

  PassswordPolicy(){
    return this.apiService.get('/Configuration/GetPasswordPolicy')
      .then(data =>{
        return data;
      })
  }

  GetEmployeeExitDetails(id)
  {
    return this.apiService.getParam('/Exit/GetEmployeeExitDetails?EmployeeID',id)
      .then(response => 
        { 
          return response;  
        });
  }
  
 GetExitQuestions(personiD,enableexitID,exitTypeID,PageFrom){
  return this.apiService.getParameters(`/Exit/GetExitQuestions?EmployeeID=${personiD}&enableexitID=${enableexitID}&exitTypeID=${exitTypeID}&PageFrom=${PageFrom}`)
      .then(data => {
          return data;
      })
}
PostResignationRequest(data){
  console.log(data);
  return this.apiService.post('/Exit/PostResignationRequest',data)
  .then(data => {
    return data;
    console.log(data);
  })
}
PostResignationWithdraw(data){
  return this.apiService.post('/Exit/PostResignationWithdraw',data)
  .then(data => {
    return data;
  })
}

GetNoticePeriodByPositionId(resdate,empid)
{
    return this.apiService.getParameters(`/Exit/GetNoticePeriodByPositionId?ResignationDate=${resdate}&EmployeeID=${empid}`)
        .then(data =>
        {
            return data;
        });
}
    GetApproversValidation(empid){

        return this.apiService.getParameters(`/Exit/GetApproversValidation?EmployeeID=${empid}`)
            .then(data =>{
                return data;
            })

    }
}
