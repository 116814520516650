import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';


@inject(ApiService)
export class DashboardService {
  
  constructor(apiService) {
    this.apiService = apiService; 
  }

  BindDynamicMenu(employeeId) 
  {
     return this.apiService.getParam('/Menu/GetDynamicMenu?EmployeeId',employeeId)
      .then(data => 
        {
          return data;  
        }); 
  }
    GetDashboardDetails(employeId){
        return this.apiService.getParam('/Dashboard/GetDashboardDetails?employeeID',employeId)
            .then(data=>{
                //console.log(data);
                return data
            });
    }

    GetServerCurrentDate(employeId){
      return this.apiService.getParam('/Dashboard/GetServerCurrentDate?EmployeeID',employeId)
          .then(data=>{
              //console.log(data);
              return data
          });
  }
 
    GetAllFavorites(employeId){
      return this.apiService.getParam('/Menu/GetAllFavorites?EmployeeID',employeId)
          .then(data=>{
              //console.log(data);
              return data
          });
  }

  GetRecentItems(employeId){
    return this.apiService.getParam('/Menu/GetRecentItems?EmployeeID',employeId)
        .then(data=>{
            //console.log(data);
            return data
        });
}

}
