import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';

@inject(ApiService)
export class AttendanceService {

    constructor(apiService) {
        this.apiService = apiService;
    }

    GetAttendanceDetails(empid){
        return this.apiService.getParam('/Attendance/GetAttendanceDetailsByEmployeeID?employeeID', empid).then(data =>
            {
                return data;
            });
    }

    SaveAttendance(data){
        return this.apiService.post('/Attendance/PostAttendance/',data)
    }

    GetMyAttendanceDetails(employeeID,month,year){
      return this.apiService.getParameters(`/Attendance/GetMyAttendanceDetails?employeeID=${employeeID}&month=${month}&year=${year}`).then(data =>
          {
              return data;
          });
    }

   GetAttendanceSummary(employeeID,month,year){
    return this.apiService.getParameters(`/Attendance/GetMyAttendanceSummary?employeeID=${employeeID}&month=${month}&year=${year}`).then(data =>
        {
            return data;
        });
    }

    GetAttendanceMonth(){
      return this.apiService.get('/Employee/GetMonths')
    }

    GetAttendanceYear(){
      return this.apiService.get('/Employee/GetYearForDropdown')
    }

    GetAdminAttendanceSummary(data){
      return this.apiService.post('/Attendance/GetEmployeeAttendanceSummary',data).then(data =>
        {
            return data;
        });
    }

    DepartmentDropdown(EmployeeId,StatusCode){
      return this.apiService.getParameters(`/Employee/GetDepartmentsForDropdown?EmployeeId=${EmployeeId}&StatusCode=${StatusCode}`).then(data =>
        {  
          return data;
        });
    }

    GetAllEmployeeNamesDropdown(EmployeeId,StatusCode,DepartmentIds){
      return this.apiService.getParameters(`/Employee/GetEmployeesForDropdown?EmployeeId=${EmployeeId}&StatusCode=${StatusCode}&DepartmentIds=${DepartmentIds}`)
      .then(data => {
        return data;
        });
    }

    GetAttendanceDetailsMobile(employeeID,date){
      return this.apiService.getParameters(`/Attendance/GetEmployeeAttendanceDetailsByID?EmployeeID=${employeeID}&Date=${date}`).then(data =>
          {
              return data;
          });
    }

    SaveAttendanceData(data){
      return this.apiService.post('/Attendance/PostEmployeeAttendance',data)
    }

    ValidateAttendance(data){
      return this.apiService.post('/Attendance/ValidateAttendance',data)
    }

    ChangeRequestGrid(EmployeeID,Date){
      return this.apiService.getParameters(`/Attendance/GetAttendancePendingRequest?EmployeeID=${EmployeeID}&Date=${Date}`)
      .then(data => {
        return data;
      })
    }

    AttentendencesWorkflowApproverExist (EmployeeId) {
      return this.apiService.getParam('/Attendance/IsAttendanceWorkflowApproverExist?EmployeeId',EmployeeId)
          .then(data => {
              return data;
          });
  }


}
