import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';


@inject(ApiService)
export class SupportService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    SaveSupportForm(data){
        return this.apiService.post('/Support/PostSupport',data)
            .then(data => {
                return data;
            });
    }
    getLocations(){
        return this.apiService.get('/Employee/GetAllLocationsForDropdown')
            .then(data => {
                return data;
            });
    }
    getModulesData(){
        return this.apiService.get('/Support/GetConfigurationModules')
            .then(data => {
                return data;
            });
    }

    getFeedbackTypes(){
        return this.apiService.get('/Support/GetFeedbackTypes')
            .then(data => {
                return data;
            });
    }
    getLocationsByEmployeeId(EmployeeId){
        return this.apiService.getParam('/Support/GetLocationsByEmployeeIdForDropdown?EmployeeId' ,EmployeeId)
            .then(data => {
                return data;
            });
    }

}
