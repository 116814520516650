import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';


@inject(ApiService)
export class PerformanceMangemantService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    PerformanceList(data)
    {
        return this.apiService.post('/KRA/GetMyGoalSheet?EmployeeID',data)
            .then(data =>
            {
                return data;
            });
    }



    AcceptKraMyemployee(data)
    {
        return this.apiService.post('/KRA/PostMyKRADetails',data)
            .then(data =>
            {
                return data;
            });
    }
    GetReviewHistory(EmployeeID,PeriodID,ReviewTypeID)
    {
        return this.apiService.getParameters(`/KRA/GetReviewHistory?EmployeeID=${EmployeeID}&PeriodID=${PeriodID}&ReviewTypeID=${ReviewTypeID}`)
            .then(data =>
            {

                return data;
            });
    }


    GetAdditionalNotesandComments(KRAPerformanceReviewID)
    {
        return this.apiService.getParam('/KRA/GetAdditionalNotesandComments?KRAPerformanceReviewID',KRAPerformanceReviewID)
            .then(data =>
            {
                return data;
            });
    }

    KraCategories(EmployeeID,PeriodID,ReviewTypeID,ViewID)
    {
        return this.apiService.getParameters(`/KRA/GetMyKRADetails?EmployeeID=${EmployeeID}&ViewID=${ViewID}&PeriodID=${PeriodID}&ReviewTypeID=${ReviewTypeID}`)
            .then(data =>
            {
                return data;
            });
    }

    ViewKRAPRSettings(PeriodID)
    {

        return this.apiService.getParameters(`/KRA/ViewKRAPRSettings?PeriodID=${PeriodID}`)
            .then(data => {
                return data;
            })

    }
    GetViewApplicabilityForKRA(PeriodID)
    {

        return this.apiService.getParameters(`/KRA/GetViewApplicabilityForKRA?PeriodID=${PeriodID}`)
            .then(data => {
                return data;
            })

    }

    GetAchievements(EmployeeGoalID,ReviewTypeID,ViewID)
    {
        return this.apiService.getParameters(`/KRA/GetAchievements?ReferenceID=${EmployeeGoalID}&ReviewTypeID=${ReviewTypeID}&ViewID=${ViewID}`)
            .then(data =>
            {
                return data;

            });
    }

    GetEditCreateAchievements(AchievementID,ViewID)
    {
        return this.apiService.getParameters(`/KRA/GetCreateAchievements?AchievementID=${AchievementID}&ViewID=${ViewID}`)
            .then(data =>
            {
                return data;

            });
    }


    Achievementsdata(data)
    {
        return this.apiService.post('/KRA/PostSubmitAchievements',data)
            .then(data =>
            {
                return data;
            });
    }
    addAchievements(data)
    {
        return this.apiService.post('/KRA/PostAchievements',data)
            .then(data =>
            {
                return data;
            });
    }
    DeleteAchievements(data){

        return this.apiService.post('/KRA/DeleteAchievements',data)
            .then(data =>
            {
                return data;
            });

    }
    MarkasCompletedKraCategories(markascompletedata){
            return this.apiService.post('/KRA/PostMarkasCompleted', markascompletedata)

        }

    GetMarkasCompleted(EmployeeGoalID){
            return this.apiService.getParameters(`/KRA/GetMarkasCompleted?EmployeeGoalID=${EmployeeGoalID}`)
                .then(data => {
                    return data;
                })
        }
    PerformancereviewintiationList(empid)
    {
        return this.apiService.getParam('/KRA/PerformanceReviewInitiation?EmployeeID',empid)
            .then(data =>
            {
                return data;
            });
    }
    GetEmployeePerformance(empid){
        return this.apiService.getParam('/KRA/GetEmployeePerformance?EmployeeID',empid)
            .then(data =>
            {
                return data;
            });
    }
    GetExemptEmployeeData(empid){
        return this.apiService.getParam('/KRA/GetExemptEmployee?EmployeePerformanceReviewID',empid)
            .then(data =>
            {
                return data;
            });
    }
    GetEmployeePerformanceFilterData(EmployeeID,DepartmentIds,PositionIds,ReviewType)
    {
        return this.apiService.getParameters(`/KRA/GetEmployeePerformanceFilter?EmployeeID=${EmployeeID}&DepartmentIds=${DepartmentIds}&PositionIds=${PositionIds}&ReviewType=${ReviewType}`)
            .then(data =>
            { console.log(data);
                return data;
            });
    }
    PostBulkAssignData(data){
        return this.apiService.post('/KRA/GetBulkAssignEmployeeList',data)
            .then(data =>
            {

                return data;
            });

    }
    DeleteBulkAssignData(AssignEmployeeByID){
        return this.apiService.post('/KRA/DeleteBulkAssignEmployeeByID',AssignEmployeeByID)
            .then(data =>
            {

                return data;
            });

    }
    PostExemptEmployeeData(data)
    {
        return this.apiService.post('/KRA/PostExemptEmployee',data)
            .then(data =>
            {

                return data;
            });
    }
    DepartmentDropdown(EmployeeId,StatusCode)
    {
        return this.apiService.getParameters(`/Employee/GetDepartmentsForDropdown?EmployeeId=${EmployeeId}&StatusCode=${StatusCode}`)
            .then(data =>
            {
                return data;
            });
    }

    GetPerformanceReviewInitiation(data)
    {
        return this.apiService.post('/KRA/GetPerformanceReviewInitiation',data)
            .then(data =>
            {
                return data;
            });
    }

    GetMyCompOffDetails(data)
    {
        return this.apiService.post('/Attendance/GetMyCompOffDetails',data)
            .then(data =>
            {
                return data;
            });
    }
    CheckDuplicateAchievement(data)
    {
        return this.apiService.post('/KRA/CheckDuplicateAchievement',data)
            .then(data =>
            {
                return data;
            });
    }


}