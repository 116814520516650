export class JwtService {
  
  getToken() {
    return window.localStorage['jwtToken'];
  }
  
  saveToken(token) {
    window.localStorage['jwtToken'] = token;
  }
  
  destroyToken() {
    window.localStorage.removeItem('jwtToken');
  }

  getEmployeeId(){
    return window.localStorage['EmployeeID'];
  }
  getLocationId(){
    return window.localStorage['LocationID'];
  }

  getEmployeeClass(){
    return window.localStorage['EmployeeClass'];
  }

  getService(){
    return window.localStorage['MsalService'];
  }

  saveEmployeeId(employeeId){    
    window.localStorage['EmployeeID'] = employeeId;
  }
  
  saveLocationId(LocationID){    
    window.localStorage['LocationID'] = LocationID;
  }

  saveEmployeeClass(employeeClass){
    window.localStorage['EmployeeClass'] = employeeClass;
  }

  saveSignInService(service){
    window.localStorage['MsalService'] = service;
  }
  
  removeEmployeeId() {
    window.localStorage.removeItem('EmployeeID');
  } 
  removeLocationId() {
    window.localStorage.removeItem('LocationID');
  }

  removeEmployeeClass() {
    window.localStorage.removeItem('EmployeeClass');
  }

  removeService() {
    window.localStorage.removeItem('MsalService');
  }

  // saveRememberMe(username,password)
  // {
  //   window.localStorage['UserName'] = username;
  //   window.localStorage['Password'] = password;
  // }

  // getUserName(){
  //   return window.localStorage['UserName'];
  // }
  // getPassword(){
  //   return window.localStorage['Password'];
  // }
}
