import {inject} from 'aurelia-dependency-injection';
import {ApiService} from './apiservice';
import {data} from 'jquery';

@inject(ApiService)
export class SalaryRevisionService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    PostEmployeeSalaryRevision(listData) {
        return this.apiService.post('/SalaryRevision/PostEmployeeSalaryRevision', listData)
            .then(data => {
                return data;
            });
    }
    DepartmentDropdown(EmployeeId, StatusCode) {
        return this.apiService.getParameters(`/Employee/GetDepartmentsForDropdown?EmployeeId=${EmployeeId}&StatusCode=${StatusCode}`)
            .then(data => {
                return data;
            });
    }
    GetPersonFavoriteItems(employeeId, sitemapId) {
        return this.apiService.getParameters(`/Menu/GetPersonFavoriteItems?EmployeeID=${employeeId}&SiteMapID=${sitemapId}`)
            .then(data => {
                return data;
            });
    }
    AddToFavourites(employeeId, sitemapId) {
        return this.apiService.getParameters(`/Menu/AddToFavouriteItems?EmployeeID=${employeeId}&SiteMapID=${sitemapId}`)
            .then(data => {
                return data;
            });
    }
    getEmployeeDetails(SalaryRevisionID) {
        return this.apiService.getParameters(`/SalaryRevision/GetSalaryRevisionByID?SalaryRevisionID=${SalaryRevisionID}`)
    }
    Questionlist(SalRevisionID,EmployeeID){
        return this.apiService.getParameters(`/SalaryRevision/GetSalaryRevisionQuestionsListByID?SalRevisionID=${SalRevisionID}&EmployeeID=${EmployeeID}`)
    }
    AppreciationByEmployeeID(EmployeeID,StartDate,EndDate){
        return this.apiService.getParameters(`/SalaryRevision/GetAppreciationByEmployeeID?EmployeeID=${EmployeeID}&FromDate=${StartDate}&ToDate=${EndDate}`)
    }
    GetTimeoffSummaryByPersonID(PersonId,StartDate,EndDate){
        return this.apiService.getParameters(`/SalaryRevision/GetTimeoffSummaryByPersonID?PersonId=${PersonId}&StartDate=${StartDate}&EndDate=${EndDate}`)
    }
    GetOtherPositionDetails(Position,LevelName,Department,PersonId,LoggedInEmployeeId){
        return this.apiService.getParameters(`/SalaryRevision/GetOtherPositionDetails?Position=${Position}&LevelName=${LevelName}&Department=${Department}&PersonId=${PersonId}&LoggedInEmployeeId=${LoggedInEmployeeId}`)
    }

    getEmployeeSalaryRevision(FromDate,ToDate,Status,LoggedInEmployeeId)
    {
        return this.apiService.getParameters(`/SalaryRevision/GetEmployeeSalaryRevision?FromDate=${FromDate}&ToDate=${ToDate}&Status=${Status}&LoggedInEmployeeId=${LoggedInEmployeeId}`)
        .then(data =>{
           return data;
          
        });
    }

    GetCertificationsByPersonID(PersonId,Fromdate,Todate){
        return this.apiService.post(`/SalaryRevision/GetCertificationsByPersonID?Personid=${PersonId}&FromDate=${Fromdate}&ToDate=${Todate}`)
        .then(data =>{
            return data;
        });
    }


}

